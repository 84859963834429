(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
module.exports={
	"reglamentos": [
		{
			"id": "1",
			"item": "All  rules,  regulations  and  payoffs  contained  herein  are  subject  to  changes  and  revisions  by  AMEXBET.MX without prior written notice."
		},
		{
			"id": "2",
			"item": "Any sports or horse ruling, not specifically stated in the specific rules or regulations, will be decided by the official Las Vegas rules."
		},
		{
			"id": "3",
			"item": "AMEXBET.MX shall not be responsible for any damages or losses deemed or alleged to have resulted from or been caused by this Web site or its content. This includes any person's use or misuse of its content, the inability  of  any  person  to  connect  with  or  use  the  site,  delay  in  operations  or  transmission,  failure  of communication lines, or any errors or omissions in content."
		},
		{
			"id": "4",
			"item": "All  clients  must  be  at  least  the  legal  alcoholic  beverage  drinking  age  in  their  particular  country,  state  or jurisdiction."
		},
		{
			"id": "5",
			"item": "All rules, regulations and wagering odds, will constantly be updated on our web site, so please double check any issue or difference in the rules before making a claim to our customer service staff."
		},
		{
			"id": "6",
			"item": "AMEXBET.MX reserves the right to limit or refuse a wager for any reason, and has the right to refuse wagers from clientele who are from states or jurisdiction where online wagering is deemed illegal."
		},
		{
			"id": "7",
			"item": "All wagers accepted by AMEXBET.MX will be honored, unless there is proof of cheating, attempted hacking or  knowledgeable  deceit.  Any  wager  with  BAD  or  INNACURATE  odds  can  be  cancelled  by  AMEXBET.MX group without prior notice to the client."
		},
		{
			"id": "8",
			"item": "Minimum and maximum wager amounts on all sporting events will be determined by AMEXBET.MX and are subject  to  change  without  prior  written  notice.  AMEXBET.MX  also  reserves  the  right  to  adjust  limits  on individual accounts."
		},
		{
			"id": "9",
			"item": "AMEXBET.MX will not accept a wager without the proper account number and password for both telephone and online wagering."
		},
		{
			"id": "10",
			"item": "Each client is responsible for maintaining the secrecy of his betting account and should make every effort to prevent the use of his personal account number by any third party. Any transactions entered into where the account holder's number and password have been given to effect a wager shall stand as bona fide transactions dependent only upon the current balance in the account."
		},
		{
			"id": "11",
			"item": "All  accounts  are  kept  private  and  confidential.  All  telephone  calls  are  recorded  for  the  protection  of AMEXBET.MX and its clients for the purpose of possible disputes. You must claim a dispute within 7 days of the graded wager."
		},
		{
			"id": "12",
			"item": "When wagering on the Internet, members are solely responsible for their own account transactions. Please be  sure  to  review  your  wagers  for  any  mistakes  before  sending  them  in.  AMEXBET.MX  does  not  take responsibility for missing or duplicate wagers made by the client and will not entertain discrepancy requests because a play is missing or duplicated. Clients may review their transactions in the Review Account section of the site after each session to ensure all requested wagers were accepted. CHECK ALL TRANSACTIONS CAREFULLY!!"
		},
		{
			"id": "13",
			"item": "Wagers placed online or over the phone can be changed or cancelled by the customer if all the following conditions are met:",
			"list": [
				"The lines and odds are the same as those of the original wager",
				"Not more than 5 minutes have passed since the time of the original bet",
				"The games have not started"
			]
		},
		{
			"id": "14",
			"item": " After wagers are placed by telephone, the clerk will read back the wagers. If the read back or confirmation is correct, client must re-confirm wagers by stating their client number and password. If any mistakes were made by the clerk, it is the responsibility of the client to tell the clerk before the call is completed. Once the client confirms a read back the wagers are final. If a client does not confirm his plays by giving his code and password and hangs up before the read back all plays are considered final."
		},
		{
			"id": "15",
			"item": "If the client has phone problems and is cut off before getting a read back, it is his responsibility to call as soon as possible and verify his pending wagers. If the client gave the clerk a team and amount before getting cut off the wagers will go into the system even though there was no read back."
		},
		{
			"id": "16",
			"item": "Wagers are graded only after the contest is final."
		},
		{
			"id": "17",
			"item": "Baseball, Football, Basketball, Hockey and soccer events must be played on the specified game date. Any game that is cancelled or postponed will be deemed 'no action' and funds will be returned to your account.- Tennis, Boxing, Nascar and Golf must be played within 7 days of the originally scheduled date."
		},
		{
			"id": "18",
			"item": "Concerning  suspended  games,  continued  games  and  overturned  decisions,  AMEXBET.MX  follows  the generally accepted Las Vegas gaming rules."
		},
		{
			"id": "19",
			"item": "In  all  future  wagering  (for  example,  Super  Bowl  winner,  etc.),  the  winner  as  determined  by  the  League Commissioner  will  also  be  declared  the  winner  for  betting  purposes.  For  the  purposes  of  NCAA  Football. wagering, the winner of the BCS Championship game will be declared the National Champion. Any future type wager will not count towards any rollover requirement."
		},
		{
			"id": "20",
			"item": "On horse racing, the clerk will give the player a POST TIME once the ticket is completed. This POST TIME is the one that will be compared with times posted at the grading website. The POST TIME given to the customer must be the same or earlier than the OFF TIME posted at the site. Our post times are non-negotiable and final. Any Horse Wager will not count towards Any Rollover Requirement."
		},
		{
			"id": "21",
			"item": "AMEXBET.MX  relies  entirely  upon  the  information  provided  by  the  customer  in  the  application  in  order  to establish  a  wagering  account.  Payouts  will  only  be  made  to  the  name  in  the  account.  Checks  or  Western Unions to third parties are not allowed."
		},
		{
			"id": "22",
			"item": "In the event funds are credited to a customer's account in error, it is incumbent upon the customer to notify AMEXBET.MX the aforesaid error without delay. Unless otherwise notified by AMEXBET.MX, any transactions made shall be void."
		},
		{
			"id": "23",
			"item": "Although our computer system keeps exact customer balances, it is the client's responsibility to make sure his balance is correct every time your account number is logged in or by phone when applicable."
		}
	],
	"sports": [
		{
			"item": "NFL"
		},
		{
			"item": "NBA"
		},
		{
			"item": "NHL"
		},
		{
			"item": "MBL"
		},
		{
			"item": "College Football"
		},
		{
			"item": "College Basketball"
		},
		{
			"item": "GolfBoxig"
		},
		{
			"item": "Canadian Football"
		},
		{
			"item": "Soccer"
		},
		{
			"item": "Tennis"
		},
		{
			"item": "Nascar"
		}
	],
	"bets": [
		{
			"td1": "Pro Football",
			"td2": "55 minutes of play"
		},
		{
			"td1": "College Football",
			"td2": "55 minutes of play"
		},
		{
			"td1": "Basketball",
			"td2": "55 minutes of play"
		},
		{
			"td1": "Pro Basketball",
			"td2": "43 minutes of play"
		},
		{
			"td1": "College Basketball",
			"td2": "35 minutes of play"
		},
		{
			"td1": "Hockey",
			"td2": "55 minutes of play"
		},
		{
			"td1": "Baseball Sides",
			"td2": "5 innings (8 1/2 if the home team is winning)"
		},
		{
			"td1": "Baseball Totals",
			"td2": "9 innings (8 1/2 if the home team is winning)"
		},
		{
			"td1": "Run Lines",
			"td2": "9 innings (8 1/2 if the home team is winning)"
		},
		{
			"td1": "Tennis",
			"td2": "One ball served"
		},
		{
			"td1": "Soccer",
			"td2": "If a match is suspended before full time is reached, and not completed the same day, wagers on the outcome of the match are considered void and all wagers will be refunded. Includes Goal Lines, Money lines and Totals."
		}
	],
	"lenguajes": [
		{
			"text": "en"
		},
		{
			"text": "es"
		}
	],
	"sportList": [
		{
			"text": "football",
			"id": "football"
		},
		{
			"text": "basketball",
			"id": "basketball"
		},
		{
			"text": "baseball",
			"id": "baseball"
		},
		{
			"text": "Hockey",
			"id": "Hockey"
		},
		{
			"text": "soccer",
			"id": "soccer"
		},
		{
			"text": "golf",
			"id": "golf"
		},
		{
			"text": "Boxeo",
			"id": "boxeo"
		},
		{
			"text": "tennis",
			"id": "tennis"
		},
		{
			"text": "autorancing",
			"id": "autorancing"
		},
		{
			"text": "general props rules",
			"id": "general-props-rules"
		},
		{
			"text": "live-in-game",
			"id": "live-in-game"
		},
		{
			"text": "Parlay Cards Rules",
			"id": "parlay"
		},
		{
			"text": "rugby",
			"id": "rugby"
		},
		{
			"text": "australian rules",
			"id": "australian-rules"
		},
		{
			"text": "cricket",
			"id": "cricket"
		},
		{
			"text": "olympic rules",
			"id": "olympic-rules"
		},
		{
			"text": "e-sports",
			"id": "e-sports"
		},
		{
			"text": "volleyball",
			"id": "volleyball"
		},
		{
			"text": "water polo",
			"id": "water-polo"
		}
	]
}

},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
function initAcc(elem, option) {
    document.addEventListener('click', function (e) {
        if (!e.target.matches(elem + ' .a-btn')) return;else {
            if (!e.target.parentElement.classList.contains('active')) {
                if (option == true) {
                    var elementList = document.querySelectorAll(elem + ' .a-container');
                    Array.prototype.forEach.call(elementList, function (e) {
                        e.classList.remove('active');
                    });
                }
                e.target.parentElement.classList.add('active');
            } else {
                e.target.parentElement.classList.remove('active');
            }
        }
    });
}
initAcc('.accordion.v1', true);
initAcc('.accordion.v2', false);
exports.default = initAcc;

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _dataEn = require('../../data/data-en.json');

var _dataEn2 = _interopRequireDefault(_dataEn);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var multilanguages = function multilanguages(root, idiom2) {
	var items = document.querySelectorAll('.login__btns--a');
	var url = document.querySelector('.no-js');

	var _loop = function _loop(i) {
		items[i].addEventListener('click', function (e) {
			e.preventDefault();
			var value = items[i].getAttribute('value');
			console.log(value);
			for (var y = 0; y < _dataEn2.default.lenguajes.length; y++) {
				if (_dataEn2.default.lenguajes[y].text !== 'es') {
					if (_dataEn2.default.lenguajes[y].text === value) {
						window.location.pathname = value + '/' + idiom2;

						//ESTA LOCATION ES PARA VERLO EN GITHUB PAGES
						// window.location.pathname = `amexbet/${value}/${idiom2}`
					} else {
						window.location.pathname = root;

						//ESTA LOCATION ES PARA VERLO EN GITHUB PAGES
						// window.location.pathname = `amexbet/${root}`;
					}
				}
			}
		});
	};

	for (var i = 0; i < items.length; i++) {
		_loop(i);
	}
};

exports.default = multilanguages;

},{"../../data/data-en.json":1}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
var tnsSlider = function tnsSlider() {
	var slider = tns({
		container: "#tnsSlider",
		items: 2,
		// axis: "vertical",
		controlsPosition: "bottom",
		slideBy: 1,
		swipeAngle: false,
		autoplayButtonOutput: false,
		speed: 400,
		edgePadding: 16,
		nav: false,
		autoplay: true,
		mouseDrag: true,
		mode: "gallery",
		controlsText: ['<i class="tns-carousell__prev fas fa-chevron-left"></i>', '<i class="tns-carousell__next fas fa-chevron-right"></i>'],
		responsive: {
			320: {
				items: 2
			},
			480: {
				items: 2
			}
		}
	});
};

exports.default = tnsSlider;

},{}],5:[function(require,module,exports){
'use strict';

var _multilanguages = require('./components/multilanguages');

var _multilanguages2 = _interopRequireDefault(_multilanguages);

var _tnsSlider = require('./components/tns-slider');

var _tnsSlider2 = _interopRequireDefault(_tnsSlider);

var _dropdown = require('./components/dropdown');

var _dropdown2 = _interopRequireDefault(_dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(function () {
	if (document.body.classList.contains('inicio') || document.body.classList.contains('home')) {
		(0, _multilanguages2.default)('index.html', 'index.html');
		// functions here
	} else if (document.body.classList.contains('reglas-de-casa') || document.body.classList.contains('house-rules')) {
		(0, _multilanguages2.default)('reglas-de-casa.html', 'house-rules.html');
		// functions here
	} else if (document.body.classList.contains('reglas-de-deportes') || document.body.classList.contains('sport-rules')) {
		(0, _dropdown2.default)();
		(0, _tnsSlider2.default)();
		(0, _multilanguages2.default)('reglas-de-deportes.html', 'sport-rules.html');
		// functions here
	}
})(); // import {topNav} from './components/topNav';

},{"./components/dropdown":2,"./components/multilanguages":3,"./components/tns-slider":4}]},{},[5]);
